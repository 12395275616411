import * as React from "react";
import {Link, useTranslation} from "gatsby-plugin-react-i18next";
import {Col} from "react-bootstrap";
import {GatsbyImage} from "gatsby-plugin-image";

export default function BlogCard({item, blogImage, index}) {
    const { t } = useTranslation();

    return (
        <Col md={6} xxl={4} className="mb-2 mb-xl-3">
            <div className="blog-card portfolio__item d-flex flex-wrap justify-content-between flex-column px-md-2 px-xl-3 my-xl-3 position-relative">
                <div className="portfolio__item-img position-relative">
                    <GatsbyImage image={blogImage} alt={item.h1}/>
                    <div className="fake-block position-absolute w-100 h-100 d-none d-xl-flex justify-content-center align-items-center grad flex-wrap">
                        <div className="btn btn__big white-btn text-center text-white mt-5">{t('moreWord')}</div>
                        <div className="blog-card__category w-100 text-center">- {t(item.category)} -</div>
                    </div>
                </div>
                {item.h1 &&
                    <p className="portfolio__item-name h4 text-uppercase opacity-50 mb-0 px-xl-2 px-xxl-3 py-2 py-xl-3 text-center mt-2 mt-md-3 mb-xl-2">{t(item.h1)}</p>
                }
                <Link to={item.slug} className="fake-block position-absolute w-100 h-100 px-2 d-flex justify-content-center align-items-center" title={t(item.h1)} />
            </div>
        </Col>
    )
}