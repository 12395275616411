import * as React from "react";
import {useTranslation} from "gatsby-plugin-react-i18next";
import {StaticImage} from "gatsby-plugin-image";
import {Col, Row} from "react-bootstrap";

export default function BlogWidget() {
    const { t } = useTranslation();

    return (
        <div>
            <p className="h6">{t('advertisementWord')}:</p>
            <Row>
                <Col xs={6} xxl={12}>
                    <a href="//izstekla.net.ua/" target="__blank">
                        <StaticImage
                            src="../../images/banners/izSTEKLA.png"
                            width={640}
                            quality={95}
                            formats={["auto", "webp", "avif"]}
                            alt="izSTEKLA"
                            className="w-100 mb-2 mb-xl-3"
                        />
                    </a>
                </Col>
                <Col xs={6} xxl={12}>
                    <StaticImage
                        src="../../images/banners/640x280.png"
                        width={640}
                        quality={85}
                        formats={["auto", "webp", "avif"]}
                        alt="izSTEKLA"
                        className="w-100 mb-2 mb-xl-3"
                    />
                </Col>
            </Row>
            <p className="advertisement-text">{t('advertisementText')}: info@avzpro.com.ua</p>
        </div>
    )
}